@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap");

:root {
  color-scheme: light only !important;
}

label:hover {
  cursor: pointer;
}

.memolock {
  height: calc(100vh - 100px);
}

img.mbti-img,
img.photo {
  max-width: 100%;
  height: auto;
}

div.captureIt {
  margin-top: 0 !important;
  overflow-x: hidden;
}

#error {
  z-index: 999999999;
}

#manualTitle {
  font-family: "Dela Gothic One", cursive;
  font-size: 1.1em;
}

#manualA {
  font-family: "Dela Gothic One", cursive;
}

pre {
  white-space: pre-wrap;
}

#adDetail .ql-container,
#cover .ql-container {
  height: 300px !important;
  background-color: white;
}

#adDetail .ql-editor,
#cover .ql-editor {
  height: 300px !important;
}

#visitmemo .ql-editor,
#visitmemo .ql-container {
  height: 300px !important;
}

.ql-container {
  height: 150px !important;
  background-color: white;
}

.ql-editor {
  height: 150px !important;
}

.blurred-editor .ql-toolbar {
  display: none;
}

#premiumCLogo {
  max-width: 100%;
  max-height: 100px;
  width: 250px;
  height: auto;
  overflow: hidden;
}

#premium_bg {
  background-image: url("https://source.unsplash.com/random/1920x1080/?business");
  background-size: cover;
}

#banner {
  background-image: url("https://source.unsplash.com/random/1920x1080/?business");
  background-size: cover;
}

.ql-size-huge {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.ql-size-large {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.ql-size-normal {
  font-size: 1rem;
  line-height: 1.5rem;
}

.ql-size-small {
  font-size: 0.8rem;
  line-height: 1.2rem;
}

.ql-align-center {
  text-align: center;
}

.ql-align-left {
  text-align: left;
}

.ql-align-right {
  text-align: right;
}

.signature-canvas {
  width: 100%;
  min-height: 200px;
  border: 1px solid #e5e7eb;
  background-color: #fafafa;
}
@page {
  margin: 10mm;
}

.print-content > img {
  vertical-align: text-top;
  display: inline-block;
  max-height: 100%;
  width: auto;
  max-width: 29%;
  margin: 0 1%;
}

.print-image {
  text-align: center;
}

@media print {
  .none-print {
    display: none;
  }
  .print-content-container {
    overflow: hidden;
  }

  .print-content-container .print-content {
    -webkit-print-color-adjust: exact;
    display: block; /* 프린트할 대상인 .print-content는 보이도록 설정 */
    width: 170mm; /* A4 가로 크기 */
    height: 250mm; /* A4 세로 크기 */
    /* 추가적인 스타일 지정 */
  }

  .print-content > table {
    width: 100%;
    max-width: 168mm;
    max-height: 250mm;
  }

  .print-content > img {
    vertical-align: text-top;
    display: inline-block;
    max-height: 100%;
    width: auto;
    max-width: 50mm;
    margin: 0 1mm;
  }
  .print-image {
    text-align: center;
  }
  .page-3 {
    margin-top: 30mm;
  }
}

.noDrag {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sidemenu {
  position: fixed;
  z-index: 10;
  width: 50%;
  background-color: #fff;
  top: 0;
  height: 100vh;
  overflow-y: scroll;
  transition: all 0.5s ease-in;
}

.sidemenu.off {
  right: -50%;
}

.sidemenu.on {
  right: 0;
}

.memo > ul > li:before {
  content: "- ";
  display: inline;
}

.memo > ol {
  margin-left: 30px;
}

.memo > ol > li {
  list-style: decimal-leading-zero;
}

.giftcategory {
  word-break: keep-all;
  white-space: nowrap;
}

@media (max-width: 800px) {
  .giftCategoryMenu {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .giftCategoryMenu::-webkit-scrollbar {
    display: none;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox에서 화살표를 숨기기 위해 필요합니다 */
}

#loginPopup {
  max-width: 640px;
}

#admin {
  min-height: 900px;
  position: relative;
}

.buyResult {
  min-height: 700px;
}

#giftAdmin {
  max-height: 95vh;
}

.no-context {
  -webkit-touch-callout: none;
}

#renewalmodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  z-index: 99999;
  max-width: 99vw;
}

#renewalmodal2 {
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-101%, -50%);
  height: auto;
  z-index: 99999;
}

.bg-kotired {
  background-color: #e60012;
}

.text-kotired {
  color: #e60012;
}

.hover-bg-kotired:hover {
  background-color: #e60012;
}

.hover-text-kotired:hover {
  color: #e60012;
}

#kotifooter {
  background-color: #222;
  color: #767676;
  padding: 20px 0;
}

#jumbotron {
  width: 100%;
  height: 512px;
  background-color: black;
  overflow-y: hidden;
}
#jumbotron3 {
  width: 100%;
  height: 512px;
  background-color: white;
  overflow-y: hidden;
}

.kotiborder {
  width: 100%;
  height: 1px;
  background-color: #e60012;
}

.titlefont {
  font-size: 60pt;
  margin-bottom: 20px;
}

.subtitlefont {
  font-size: 18pt;
}

.bg-kotigray {
  background-color: #f1f1f1;
}

.border-koti {
  border-color: #f1f1f1;
}

.border-koti:hover {
  border-color: #e60012;
}

.border-koti2 {
  border-color: #000;
}

.border-koti2:hover {
  border-color: #e60012;
}

.border-kotired {
  border-color: #e60012;
}

#strengthsection {
  width: 100%;
  height: 600px;
  position: relative;
}

#graycircle {
  border: 2px solid #f1f1f1;
  position: absolute;
  width: 480px;
  height: 480px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

#strengthpart {
  position: absolute;
  background-color: #f1f1f1;
  z-index: 20;
  width: 400px;
  height: 200px;
}
#strengthpart > #circle {
  position: absolute;
  border: 2px solid #e60012;
  background-color: #fff;
  width: 200px;
  height: 200px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}

#circle.circle1 {
  right: -100px;
}
#circle.circle2 {
  left: -100px;
}

#redcircle {
  border: 20px solid #ffeaeb;
  background-color: #e60012;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  transform: translate(-50%, -50%);
  z-index: 20;
  color: white;
}

#mainJumbo {
  height: 915px;
  width: 100%;
}

#shadowTxt {
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.45);
}

#historyyear {
  position: sticky;
  width: 400px;
  min-height: 100px;
  margin-bottom: 300px;
  left: 0;
  top: 0;
}
#historydetail {
  width: calc(100% - 400px);
  margin-left: 400px;
  margin-top: -1024px;
  min-height: 900px;
}

#historysection {
  margin-top: 105px;
}

#marqueesection {
  background-color: #f5f5f5;
}

.efficiency {
  background-color: #ededed;
}

.koticontainer {
  width: 1240px;
  max-width: 100%;
}

.infrawrap {
  width: 2570px;
  min-height: 160px;
}

.infrabox {
  width: 620px;
  min-height: 160px;
  padding: 40px 40px 40px;
  background-color: #f4f4f4;
}

.noneScroll::-webkit-scrollbar {
  display: none;
}

.noneScroll {
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}

.img-center {
  max-width: auto !important;
  width: auto;
  height: auto;
  object-fit: cover;
}

.galleryContent > p {
  font-size: 16pt;
}

.galleryContentMobile > p {
  font-size: 10pt;
}

.fixedTop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 999999;
}

#mobileJumbo {
  width: 100%;
  height: 160px;
  background-color: black;
  overflow-y: hidden;
}

.titlefont-m {
  font-size: 24pt;
  margin-bottom: 5px;
}

.subtitlefont-m {
  font-size: 10pt;
}

.infrawrap_mobile {
  width: 1230px;
  min-height: 80px;
}

.infrabox_mobile {
  width: 300px;
  min-height: 80px;
  padding: 20px 20px 20px;
  background-color: #f4f4f4;
}

div.a4page {
  width: 210mm !important;
  height: 297mm !important;
}

h2.surveyheading:before {
  content: "▣";
}

.txtShadow {
  text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
}

.pcLayer {
  margin: 0 -600px;
}
